import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import NodeServiceTeaser from 'src/components/NodeServiceTeaser/NodeServiceTeaser';

function ViewsNodeServiceTeasers() {
  const data = useStaticQuery(graphql`
    {
      allSanityService {
        nodes {
          id
          title
          slug {
            current
          }
          serviceBodySections {
            title
          }
          description
          longTitle
          previewImage {
            alt
            image {
              asset {
                gatsbyImageData(layout: FULL_WIDTH, width: 309, height: 276, placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
  `);

  const {
    allSanityService: { nodes: services },
  } = data;

  return (
    <Container className="my-6">
      <Row id="service-teasers">
        {services.map((service, i) => (
          <Col key={service.id} xs={12} className="py-6">
            <NodeServiceTeaser service={service} index={i + 1} />
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default ViewsNodeServiceTeasers;
