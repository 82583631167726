import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTheme } from 'src/hooks';
import imgFallback from 'src/utils/image';

function NodeServiceCard({ card }) {
  const { navigation } = useTheme();

  //   <img
  //   style={{ width: '250px' }}
  //   src={card.previewImageSmall?.image?.asset?.gatsbyImageData.images.fallback.src}
  //   alt={card.previewImageSmall?.image?.asset?.gatsbyImageData.images.fallback.src}
  // />

  return (
    <Container fluid className="position-relative shadow-service-card transition-2x overflow-hidden rounded-xl px-0">
      <Row noGutters>
        <Col xs={12} className="justify-content-center bg-cadet d-flex text-center">
          <GatsbyImage
            style={{ width: '100%' }}
            alt={card.previewImageSmall?.alt ?? ''}
            image={card.previewImageSmall?.image?.asset?.gatsbyImageData ?? imgFallback}
            objectFit="cover"
          />
        </Col>
        <Col
          xs={12}
          style={{ minHeight: '85px' }}
          className="d-flex justify-content-center justify-content-sm-start align-items-center align-items-sm-start bg-white py-3 px-3">
          <Link
            to={`/${navigation.services.path}/${card.slug.current}`}
            className="stretched-link text-size-md font-weight-black text-black">
            {card.title}
          </Link>
        </Col>
      </Row>
    </Container>
  );
}

NodeServiceCard.propTypes = {
  card: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    image: PropTypes.shape({}),
    link: PropTypes.string,
  }).isRequired,
};

export default NodeServiceCard;
