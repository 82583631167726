import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import NodeServiceCard from 'src/components/NodeServiceCard/NodeServiceCard';

function ViewsNodeServiceCards() {
  const data = useStaticQuery(graphql`
    {
      allSanityService {
        nodes {
          id
          title
          slug {
            current
          }
          previewImageSmall {
            alt
            image {
              asset {
                gatsbyImageData(layout: FIXED, width: 153, height: 130, placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
  `);

  const {
    allSanityService: { nodes: cards },
  } = data;

  return (
    <Container>
      <Row>
        {cards.map((card) => (
          <Col key={card.id} xs={12} sm={6} md={4} className="px-md-2 px-lg-3 animation-move-up py-3 px-0">
            <NodeServiceCard card={card} />
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default ViewsNodeServiceCards;
