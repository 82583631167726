import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTheme } from 'src/hooks';
import imgFallback from 'src/utils/image';

function NodeServiceTeaser({ service, index }) {
  const t = useTheme();

  return (
    <Container fluid className="px-0">
      <Row noGutters>
        <Col xs={{ span: 11, offset: 1 }} md={{ span: 6, offset: 3 }} lg={{ span: 5, offset: 0 }} xl={3} id={`service-${index}`}>
          <GatsbyImage
            image={service.previewImage?.image?.asset?.gatsbyImageData ?? imgFallback}
            alt={service.previewImage?.alt ?? ''}
            imgStyle={{ objectFit: 'contain' }}
            className="mr-xl-n4 my-4"
          />
        </Col>
        <Col xs={12} md={{ span: 8, offset: 2 }} lg={{ span: 7, offset: 0 }} xl={9}>
          <Row>
            <Col xs={12} xl={8} className="px-0">
              <Col>
                <Row className="align-items-center">
                  <Col xs={2}>
                    <span
                      className="display-3 d-block text-xl-right mr-xl-n2"
                      style={{ color: 'rgba(32, 185, 245, .16)', fontFamily: 'Bai Jamjuree, sans-serif', lineHeight: 1 }}>
                      {index < 10 ? `0${index}` : index}
                    </span>
                  </Col>
                  <Col xs={10}>
                    <h3 className="h6 text-uppercase counted-list-item mb-0">{service.title}</h3>
                  </Col>
                </Row>
              </Col>
              <Col xs={{ span: 10, offset: 2 }}>
                <div className="mb-4 text-black" style={{ letterSpacing: '0.4px', lineHeight: 1.7 }}>
                  {service.longTitle}
                </div>
                <Col
                  className="text-muted text-size-sm p-0"
                  style={{ letterSpacing: '0.4px', lineHeight: 1.7 }}
                  dangerouslySetInnerHTML={{ __html: service.description }}
                />
              </Col>
            </Col>
            <Col xs={{ span: 10, offset: 2 }} xl={{ span: 4, offset: 0 }} className="align-self-end mt-4">
              <div key={service.id} className="font-weight-black text-uppercase text-size-xs">
                {service.serviceBodySections.map((section) => section.title).join(', ')}
              </div>
              <Link to={service.slug.current} className="btn-link-primary text-uppercase mt-4">
                {t.global.more}
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

NodeServiceTeaser.propTypes = {
  service: PropTypes.shape({}).isRequired,
  index: PropTypes.number.isRequired,
};

export default NodeServiceTeaser;
