import { PortableText } from '@portabletext/react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Cta, Faq, OurProcess } from '../components/LandingPage';
import PageLayout from 'src/components/PageLayout/PageLayout';
import PageSEO from 'src/components/PageSEO/PageSEO';
import ViewsNodeServiceCards from 'src/components/ViewsNodeServiceCards/ViewsNodeServiceCards';
import ViewsNodeServiceTeasers from 'src/components/ViewsNodeServiceTeasers/ViewsNodeServiceTeasers';

function ServiceListingPage({ data, location }) {
  const {
    sanityBasicPage: {
      services: { hero, ourProcess, faq, cta },
      title,
      pageSeo,
    },
  } = data;

  const seo = {
    title: pageSeo?.title ?? title,
    description: pageSeo?.description,
    image: pageSeo?.image?.asset?.url,
    location,
  };

  return (
    <PageLayout>
      <PageSEO {...seo} />
      <Container fluid className="bg-shapes-banner px-0 pt-7">
        <Container className="py-8">
          <Row className="align-items-center">
            <Col xs={12} md={8} lg={4}>
              <h1 className="h2 mb-6">{hero.title}</h1>
              <div className="text-muted">
                <PortableText value={hero.description} />
              </div>
            </Col>
            <Col xs={12} lg={8}>
              <ViewsNodeServiceCards />
            </Col>
          </Row>
        </Container>
      </Container>
      <Container fluid className="bg-shapes-clouds-dark-v3 px-0">
        <OurProcess ourProcess={ourProcess} />
      </Container>
      <ViewsNodeServiceTeasers />
      <Faq faq={faq} />
      <Cta cta={cta} />
    </PageLayout>
  );
}

export const query = graphql`
  query ($id: String!) {
    sanityBasicPage(id: { eq: $id }) {
      title
      pageSeo {
        ...getPageSeo
      }
      services {
        hero {
          ...getHero
        }
        ourProcess {
          ...getOurProcess
        }
        faq {
          ...getFaq
        }
        cta {
          ...getCta
        }
      }
    }
  }
`;
ServiceListingPage.propTypes = {
  data: PropTypes.shape({
    sanityBasicPage: PropTypes.shape({
      title: PropTypes.string,
      slug: PropTypes.shape({
        text: PropTypes.string,
      }),
      seo: PropTypes.shape({}),
      hero: PropTypes.shape({}),
    }),
  }).isRequired,
};

export default ServiceListingPage;
